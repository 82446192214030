.red {
	text-decoration: solid underline red 4px;
}

.cbct-radio-container {
	display: flex;
	gap: 1.25rem; /* Gap of 5px */
}

/* Dropzone container styling */
.cbct-dropzone {
	border: 2px dashed #007bff;
	padding: 20px;
	text-align: center;
	border-radius: 5px;
	cursor: pointer;
	transition: border 0.3s;
}

.cbct-dropzone:hover {
	border-color: #0056b3; /* Darker blue on hover */
}

/* Dropzone file icon styling */
.cbct-icon {
	font-size: 3rem;
	color: #8faadc;
}

.cbct-message {
	margin-bottom: 10px;
	color: #6c757d;
}

/* Button inside dropzone */
.cbct-button {
	padding: 10px 20px;
	margin-top: 10px;
}

.cbct-file-list {
	list-style-type: none;
	padding: 0;
	margin-top: 15px;
}

.cbct-file-name {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.cbct-close-btn {
	cursor: pointer;
	color: #dc3545;
	margin-left: 0.5rem;
}

.cbct-download {
	padding: 10px 20px;
	margin-top: 20px;
}

.cbct-container {
	border: 1px solid #ced4da;
	border-radius: 0.375rem;
	padding: 1rem;
	background-color: #f8f9fa;
}
