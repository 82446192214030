.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(2, 2, 2, 0.7);
	opacity: 0.6;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.spinner {
	border: 7px solid #f3f3f3;
	border-top: 7px solid rgb(5, 139, 228);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
