.celeb-top-navbar {
	align-items: end !important;
}

.celeb-navbar-username {
	flex-direction: row-reverse !important;
}

.celeb-menu-item {
	font-size: 14px;
	padding: 0px 8px;
	cursor: pointer;
}

.celeb-navbar-user-thumb {
	height: 30px;
	width: 30px;
	background: #afafaf;
}

.navbar-menu-lower {
	margin-bottom: 0px !important;
}

.navbar-menu-lower {
	display: contents;
}

.navbar-menu-lower > .navbar-nav {
	height: 25px;
}

.navbar-menu-lower > .navbar-nav > .nav-link {
	font-size: 14px;
	padding: 0px 8px;
}

.scrollable-container {
	display: flex;
	overflow-x: hidden;
	white-space: nowrap;
	scrollbar-width: thin;
	scrollbar-color: #afafaf transparent;
	width: 98vw;
}
.scrollable-container:hover {
	display: flex;
	overflow-x: auto;
	white-space: nowrap;
	scrollbar-width: thin;
	scrollbar-color: #afafaf transparent;
	width: 98vw;
}
.scrollable-container::-webkit-scrollbar {
	width: 5px;
	width: 98vw;
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.image-container img {
	width: 100%;
	height: auto;
	object-fit: contain;
}

.file-name {
	font-size: 14px;
	color: #595a58;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
}

.file-name::after {
	content: attr(title);
	position: absolute;
	left: 0;
	bottom: 100%;
	background: #333;
	color: #fff;
	padding: 5px;
	border-radius: 3px;
	white-space: nowrap;
	font-size: 12px;
	transform: translateY(-5px);
	visibility: hidden;
	opacity: 0;
	transition:
		opacity 0.3s,
		visibility 0.3s;
	z-index: 1000;
}

.file-name:hover {
	visibility: visible;
	opacity: 1;
	overflow: visible;
	text-wrap: balance;
}

.file-item:hover {
	cursor: pointer;
}

/* .dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline;
  justify-content: space-around !important;
  align-items: center !important;
  align-content: center !important;
} */

.navbarOptions1 {
	transition:
		color 0.3s,
		border-bottom 0.3s,
		box-shadow 0.3s;
}

.navbarOptions1:hover {
	color: #000000;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
