.nav-link {
	cursor: pointer;
	color: #afafaf !important;
}

.nav-link.active {
	color: black !important;
}

.celebActionIcon {
	cursor: pointer;
}

.upload-file-icon {
	height: 50px;
	cursor: pointer;
}

.icon-file-upload > input {
	width: 1px;
	border: none;
	height: 1px;
	position: absolute;
	z-index: 0;
}

.celeb-task-tab .nav-item {
	position: relative;
	height: 50px;
	display: flex;
	align-items: center;
}

.celeb-task-tab .nav-link {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 10px;
}

.celeb-task-tab .nav-link.active {
	border-bottom: 3px solid white;
}

.celeb-task-tab .nav-link:not(.active) {
	border-bottom: 3px solid transparent;
}

.text-start th,
.text-start td {
	text-align: start;
}
