.button-tooth-number {
	display: inline;
	border: 1px solid #eeeeee;
	border-radius: 15px;
	padding: 5px 10px;
	font-size: 12px;
	cursor: pointer;
	margin: 1px;
}

.button-tooth-number.active {
	background-color: skyblue;
}

.button-tooth-number.disabled {
	background-color: #eeeeee;
	color: #fff;
}

.button-tooth-number.noTreatmentSelected {
	background-color: #eeeeee;
}
