.myRow {
	border: 2px solid #d9d9d9;
	text-align: center;
	height: 40px;
}

.myCol {
	border: 2px solid #d9d9d9;
}

.myHead {
	background-color: #f1f1f1;
}
