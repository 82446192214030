.sideBarPatientInfo {
	display: flex;
	flex-direction: column;
	width: -webkit-fill-available !important;
	background-color: #d9d9d9;
	padding: 6%;
	border-radius: 10px;
}

.flex-row {
	display: flex;
	gap: 10px;
	width: 100%;
}

.label {
	font-weight: 500;
	color: grey;
	text-wrap: nowrap;
}

/* .finance-container {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	align-items: center;
}

.finance-label {
	width: 50%;
	font-weight: 500;
	color: grey;
	white-space: nowrap; /* Prevent text from wrapping */
/* } */

/* .finance-data {
	width: 50%;
	font-weight: 500;
	color: grey;
	white-space: nowrap;
	text-align: left;
} */
.finance-container {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.finance-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.finance-label {
	/* font-weight: 600; */
	color: #555;
	text-align: left;
	font-size: 16px;
	white-space: nowrap;
}

.finance-data {
	width: 50%;
	font-weight: 500;
	color: #333;
	font-size: 16px;
	text-align: left;
	white-space: nowrap;
}

.view-receipt-btn:hover {
	background-color: #0056b3;
	border-color: #0056b3;
}

.view-receipt-btn:disabled {
	background-color: #ccc;
	border-color: #ccc;
}

.view-receipt-btn:disabled:hover {
	background-color: #ccc;
	border-color: #ccc;
}

.sidebar-subject {
	color: #595a58;
	font-weight: 600;
}

.file-item {
	display: flex;
	align-items: center;
	justify-content: space-between; /* Ensure space between items */
	background-color: #f8f9fa;
	border: 1px solid #dee2e6;
	border-radius: 8px;
	padding: 12px;
	cursor: pointer;
	transition:
		background-color 0.3s,
		box-shadow 0.3s;
	margin: 1% 0;
}

.file-item:hover {
	background-color: #e9ecef;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.file-item .icon-container {
	margin-right: 4%;
}

.file-item .file-name {
	font-size: 14px;
	color: #595a58;
	font-weight: 500;
	white-space: nowrap;
}

.file-item .date-container {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.file-item .date-text {
	font-size: 12px;
	color: #6c757d;
	margin-left: 5px;
	white-space: nowrap;
}

.composite-image {
	border-radius: 8px;
	background-color: #6c757d;
}

.card > div {
	display: contents;
}

.doctor-name {
	font-size: 12px;
	color: #6c757d;
}

.imgGridPlace {
	padding: 5px;
	height: 5rem;
	width: 5rem;
	object-fit: contain;
}

.listCard {
	display: flex;
	justify-content: space-around;
	background-color: #d9d9d9;
	border: none;
}

.sidebar .nav-item {
	cursor: pointer;
}

.sub-label {
	margin-left: 15px;
	font-weight: 500;
	color: grey;
	text-wrap: nowrap;
}
