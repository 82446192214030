.position-relative {
	position: relative;
}

.resetContainer {
	border: solid;
	border-color: #efefef;
	box-shadow: 5px 5px 15px #efefef;
}

.tooltip-message {
	visibility: hidden;
	width: 300px; /* Fixed width for the tooltip */
	background-color: rgba(0, 0, 0, 0.85); /* Dark background for contrast */
	color: #fff;
	text-align: center;
	border-radius: 4px; /* Rounded corners for a polished look */
	padding: 10px; /* Balanced padding */
	position: absolute;
	z-index: 1;
	left: 100%; /* Position it to the right of the input */
	top: 50%; /* Center it vertically relative to the input */
	transform: translate(10px, -50%); /* Offset to the right and centered vertically */
	transition:
		visibility 0.2s,
		opacity 0.2s ease-in-out; /* Smooth appearance */
	opacity: 0; /* Initially hidden */
	border: 1px solid #ccc; /* Subtle border for definition */
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
	font-family: Arial, sans-serif; /* Consistent font */
	font-size: 14px; /* Readable font size */
	white-space: normal; /* Allow text wrapping */
}

.position-relative:hover .tooltip-message {
	visibility: visible;
	opacity: 1; /* Show on hover */
}
