.form-button:disabled {
	background-color: rgb(202, 201, 201);
	border-color: rgb(202, 201, 201);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
