.tooth-chart {
	width: 70%;
	height: 70%;
	position: relative;
}

#Spots {
	polygon,
	path {
		-webkit-transition: fill 0.25s;
		transition: fill 0.25s;
	}
	polygon:hover,
	polygon:active,
	path:hover,
	path:active {
		fill: #b4c4dd !important;
	}
	.toothDirections {
		color: #8faadc;
		font-size: 27px;
		font-weight: bold;
	}
}

.active {
	fill: #8faadc !important;
}

.extraction {
	fill: #81818263 !important;
}
