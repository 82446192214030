.sidebar {
	height: 100%;
	width: auto;
	position: relative;
	top: 0;
	left: 0;
	background-color: #fefefe;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	flex-wrap: nowrap;
	margin-left: 2px;
	overflow-y: auto;
	overflow-x: hidden;
}

.sidebar .nav-item {
	padding: 10px 5px 10px 5px;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	color: black;
	display: block;
	width: 100%;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	margin: 5px 0px 1px 5px;
}
.sidebar .nav-item:hover {
	background-color: #d9d9d9;
	border-radius: 0px 6px 6px 1px;
}
.sidebar .nav-item.active {
	background-color: #d9d9d9;
	color: black;
	border-radius: 0px 6px 6px 1px;
}

.details-info-row {
	margin-top: 8px !important;
	color: black !important;

	height: 30px;
	text-align: center;
	font-size: x-large;
}

.details-info-label {
	text-align: left;
	font-size: large;
	color: #070707;
}

.details-info-value {
	text-align: left;
	font-size: large;
}

.img-treatment-details {
	width: 41%;
	object-fit: contain;
	float: left;
	margin-left: 7%;
	cursor: pointer;
}

.custom-button {
	background-color: white !important;
	color: black !important;
	border: 1px solid #ccc;
	font-size: 14px;
}
