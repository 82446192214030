/* .updatenode__controls {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 4;
    font-size: 12px;
  }

  .updatenode__controls label {
    display: block;
  }

  .updatenode__bglabel {
    margin-top: 10px;
  }

  .updatenode__checkboxwrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .nodeHover{
        background-color: transparent !important;
        width: auto;
				border: none !important;
				box-shadow: none !important;
				text-wrap: balance !important;
				color: black !important;
				font-size: 10px !important;
        padding: 1px !important;
        position: fixed;


  }
  .nodeHover:hover{
    color: rgb(189, 0, 0) !important;
    font-size: 17px !important;
  }

  .edgeCss{
      stroke-width:10px ;
      border: black solid 5px !important;
      box-shadow: none !important;
  }

  .taskDetails{
    display: none;
  }

  .taskCard:hover{
    .taskDetails{
      background-color: yellow;
      display: block ;
      }
  } */

/* ---------------------------------- */

.padding {
	padding: 2rem;
}

.w-32 {
	width: 32px !important;
	height: 32px !important;
	font-size: 0.85em;
}

.tl-item .avatar {
	z-index: 2;
}

.circle {
	border-radius: 500px;
}

.gd-warning {
	color: #fff;
	border: none;
	background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
}

.timeline {
	position: relative;
	border-color: rgba(160, 175, 185, 0.15);
	padding: 0;
	margin: 0;
}

.p-4 {
	padding: 1.5rem !important;
}

.block,
.card {
	background: #fff;
	border-width: 0;
	border-radius: 0.25rem;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
	margin-bottom: 1.5rem;
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

.tl-item {
	border-radius: 3px;
	position: relative;
	display: -ms-flexbox;
	display: flex;
}

.tl-item > * {
	padding: 10px;
}

.tl-item .avatar {
	z-index: 2;
}

.tl-item:last-child .tl-dot:after {
	display: none;
}

.tl-item.active .tl-dot:before {
	border-color: #448bff;
	box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-item:last-child .tl-dot:after {
	display: none;
}

.tl-item.active .tl-dot:before {
	border-color: #448bff;
	box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
	position: relative;
	border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
	content: "";
	position: absolute;
	border-color: inherit;
	border-width: 2px;
	border-style: solid;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);
}

.tl-dot:after {
	width: 0;
	height: auto;
	top: 25px;
	bottom: -15px;
	border-right-width: 0;
	border-top-width: 0;
	border-bottom-width: 0;
	border-radius: 0;
}

tl-item.active .tl-dot:before {
	border-color: #448bff;
	box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
	position: relative;
	border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
	content: "";
	position: absolute;
	border-color: inherit;
	border-width: 2px;
	border-style: solid;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);
}

.tl-dot:after {
	width: 0;
	height: auto;
	top: 25px;
	bottom: -15px;
	border-right-width: 0;
	border-top-width: 0;
	border-bottom-width: 0;
	border-radius: 0;
}

.tl-content p:last-child {
	margin-bottom: 0;
}

.tl-date {
	font-size: 0.85em;
	margin-top: 2px;
	min-width: 100px;
	max-width: 100px;
}

.avatar {
	position: relative;
	line-height: 1;
	border-radius: 500px;
	white-space: nowrap;
	font-weight: 700;
	border-radius: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-radius: 500px;
	box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.b-warning {
	border-color: #8faadc !important;
}

.b-primary {
	border-color: #448bff !important;
}

.b-danger {
	border-color: #7575758c !important;
}
.b-current {
	border-color: #df9636 !important;
}
